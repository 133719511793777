import * as bootstrap from 'bootstrap';
import Splide from '@splidejs/splide';
const $ = require('jquery');
window.jQuery = $;
window.$ = $;
window.bootstrap = bootstrap;

$(window).on('load', function () {
  $('#preloader').fadeOut('fast');
});

(function () {
  var savedConfig = sessionStorage.getItem('__CONFIG__');
  // var savedConfig = localStorage.getItem("__CONFIG__");

  var html = document.getElementsByTagName('html')[0];

  //  Default Config Value
  var defaultConfig = {
    theme: 'light',

    nav: 'vertical',

    layout: {
      mode: 'fluid',
      position: 'fixed',
    },

    topbar: {
      color: 'dark',
    },

    menu: {
      color: 'dark',
    },

    // This option for only vertical (left Sidebar) layout
    sidenav: {
      size: 'default',
      //   size: 'condensed',
      user: false,
    },
  };

  var config = Object.assign(JSON.parse(JSON.stringify(defaultConfig)), {});

  var layoutColor = html.getAttribute('data-bs-theme');
  config['theme'] = layoutColor !== null ? layoutColor : defaultConfig.theme;

  var layoutNav = html.getAttribute('data-layout');
  config['nav'] =
    layoutNav !== null
      ? layoutNav === 'topnav'
        ? 'horizontal'
        : 'vertical'
      : defaultConfig.nav;

  var layoutSize = html.getAttribute('data-layout-mode');
  config['layout']['mode'] =
    layoutSize !== null ? layoutSize : defaultConfig.layout.mode;

  var layoutMode = html.getAttribute('data-layout-position');
  config['layout']['position'] =
    layoutMode !== null ? layoutMode : defaultConfig.layout.position;

  var topbarColor = html.getAttribute('data-topbar-color');
  config['topbar']['color'] =
    topbarColor != null ? topbarColor : defaultConfig.topbar.color;

  var leftbarSize = html.getAttribute('data-sidenav-size');
  config['sidenav']['size'] =
    leftbarSize !== null ? leftbarSize : defaultConfig.sidenav.size;

  var sidebarUser = html.getAttribute('data-sidenav-user');
  config['sidenav']['user'] =
    sidebarUser !== null ? true : defaultConfig.sidenav.user;

  var menuColor = html.getAttribute('data-menu-color');
  config['menu']['color'] =
    menuColor !== null ? menuColor : defaultConfig.menu.color;

  window.defaultConfig = JSON.parse(JSON.stringify(config));

  if (savedConfig !== null) {
    config = JSON.parse(savedConfig);
  }

  window.config = config;

  if (html.getAttribute('data-layout') === 'topnav') {
    config.nav = 'horizontal';
  } else {
    config.nav = 'vertical';
  }

  if (config) {
    html.setAttribute('data-bs-theme', config.theme);
    html.setAttribute('data-layout-mode', config.layout.mode);
    html.setAttribute('data-menu-color', config.menu.color);
    html.setAttribute('data-topbar-color', config.topbar.color);
    html.setAttribute('data-layout-position', config.layout.position);
    if (config.nav === 'vertical') {
      let size = config.sidenav.size;
      if (window.innerWidth <= 767) {
        size = 'full';
      } else if (window.innerWidth >= 767 && window.innerWidth <= 1140) {
        if (self.config.sidenav.size !== 'full') {
          size = 'condensed';
        }
      }
      html.setAttribute('data-sidenav-size', size);
      if (config.sidenav.user && config.sidenav.user.toString() === 'true') {
        html.setAttribute('data-sidenav-user', true);
      } else {
        html.removeAttribute('data-sidenav-user');
      }
    }
  }
})();

/**
 * Pjax Links handler
 */
$(document).on('pjax:success', function (event, data, status, xhr, options) {
  const successAlert = xhr.getResponseHeader('X-Pjax-success');
  const errorAlert = xhr.getResponseHeader('X-Pjax-error');
  if (successAlert) {
    yii.success(successAlert);
  }
  if (errorAlert) {
    yii.error(errorAlert);
  }
});

(function ($) {
  'use strict';

  // Bootstrap Components
  function initComponents() {
    // Popovers
    const popoverTriggerList = document.querySelectorAll(
      '[data-bs-toggle="popover"]'
    );
    const popoverList = [...popoverTriggerList].map(
      (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
    );

    // Tooltips
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );

    // offcanvas
    const offcanvasElementList = document.querySelectorAll('.offcanvas');
    const offcanvasList = [...offcanvasElementList].map(
      (offcanvasEl) => new bootstrap.Offcanvas(offcanvasEl)
    );
  }

  // Left Sidebar Menu (Vertical Menu)
  function initLeftSidebar() {
    var self = this;

    if ($('.side-nav').length) {
      var navCollapse = $('.side-nav li .collapse');
      var navToggle = $(".side-nav li [data-bs-toggle='collapse']");
      navToggle.on('click', function (e) {
        return false;
      });

      // open one menu at a time only
      navCollapse.on({
        'show.bs.collapse': function (event) {
          var parent = $(event.target).parents('.collapse.show');
          $('.side-nav .collapse.show')
            .not(event.target)
            .not(parent)
            .collapse('hide');
        },
      });

      setTimeout(function () {
        var activatedItem = document.querySelector(
          'li.menuitem-active .active'
        );
        if (activatedItem != null) {
          var simplebarContent = document.querySelector(
            '.leftside-menu .simplebar-content-wrapper'
          );
          var offset = activatedItem.offsetTop - 300;
          if (simplebarContent && offset > 100) {
            scrollTo(simplebarContent, offset, 600);
          }
        }
      }, 200);

      // scrollTo (Left Side Bar Active Menu)
      function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      function scrollTo(element, to, duration) {
        var start = element.scrollTop,
          change = to - start,
          currentTime = 0,
          increment = 20;
        var animateScroll = function () {
          currentTime += increment;
          var val = easeInOutQuad(currentTime, start, change, duration);
          element.scrollTop = val;
          if (currentTime < duration) {
            setTimeout(animateScroll, increment);
          }
        };
        animateScroll();
      }
    }
  }

  function init() {
    initComponents();
    initLeftSidebar();
  }

  init();
})(jQuery);

class ThemeCustomizer {
  constructor() {
    this.html = document.getElementsByTagName('html')[0];
    this.config = {};
    this.defaultConfig = window.config;
  }

  initConfig() {
    this.defaultConfig = JSON.parse(JSON.stringify(window.defaultConfig));
    this.config = JSON.parse(JSON.stringify(window.config));
    this.setSwitchFromConfig();
  }

  changeMenuColor(color) {
    this.config.menu.color = color;
    this.html.setAttribute('data-menu-color', color);
    this.setSwitchFromConfig();
  }

  changeLeftbarSize(size, save = true) {
    this.html.setAttribute('data-sidenav-size', size);
    if (save) {
      this.config.sidenav.size = size;
      this.setSwitchFromConfig();
    }
  }

  changeLayoutMode(mode, save = true) {
    this.html.setAttribute('data-layout-mode', mode);
    if (save) {
      this.config.layout.mode = mode;
      this.setSwitchFromConfig();
    }
  }

  changeLayoutPosition(position) {
    this.config.layout.position = position;
    this.html.setAttribute('data-layout-position', position);
    this.setSwitchFromConfig();
  }

  changeLayoutColor(color) {
    this.config.theme = color;
    this.html.setAttribute('data-bs-theme', color);
    this.setSwitchFromConfig();
  }

  changeTopbarColor(color) {
    this.config.topbar.color = color;
    this.html.setAttribute('data-topbar-color', color);
    this.setSwitchFromConfig();
  }

  changeSidebarUser(showUser) {
    this.config.sidenav.user = showUser;
    if (showUser) {
      this.html.setAttribute('data-sidenav-user', showUser);
    } else {
      this.html.removeAttribute('data-sidenav-user');
    }
    this.setSwitchFromConfig();
  }

  resetTheme() {
    this.config = JSON.parse(JSON.stringify(window.defaultConfig));
    this.changeMenuColor(this.config.menu.color);
    this.changeLeftbarSize(this.config.sidenav.size);
    this.changeLayoutColor(this.config.theme);
    this.changeLayoutMode(this.config.layout.mode);
    this.changeLayoutPosition(this.config.layout.position);
    this.changeTopbarColor(this.config.topbar.color);
    this.changeSidebarUser(this.config.sidenav.user);
    this._adjustLayout();
  }

  initSwitchListener() {
    var self = this;
    document
      .querySelectorAll('input[name=data-menu-color]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeMenuColor(element.value);
        });
      });

    document
      .querySelectorAll('input[name=data-sidenav-size]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeLeftbarSize(element.value);
        });
      });

    document
      .querySelectorAll('input[name=data-bs-theme]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeLayoutColor(element.value);
        });
      });
    document
      .querySelectorAll('input[name=data-layout-mode]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeLayoutMode(element.value);
        });
      });

    document
      .querySelectorAll('input[name=data-layout-position]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeLayoutPosition(element.value);
        });
      });
    document
      .querySelectorAll('input[name=data-layout]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          window.location =
            element.value === 'horizontal'
              ? 'layouts-horizontal.html'
              : 'index.html';
        });
      });
    document
      .querySelectorAll('input[name=data-topbar-color]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeTopbarColor(element.value);
        });
      });
    document
      .querySelectorAll('input[name=sidebar-user]')
      .forEach(function (element) {
        element.addEventListener('change', function (e) {
          self.changeSidebarUser(element.checked);
        });
      });

    //TopBar Light Dark
    var themeColorToggle = document.getElementById('light-dark-mode');
    if (themeColorToggle) {
      themeColorToggle.addEventListener('click', function (e) {
        if (self.config.theme === 'light') {
          self.changeLayoutColor('dark');
        } else {
          self.changeLayoutColor('light');
        }
      });
    }

    var resetBtn = document.querySelector('#reset-layout');
    if (resetBtn) {
      resetBtn.addEventListener('click', function (e) {
        self.resetTheme();
      });
    }

    var menuToggleBtn = document.querySelector('.button-toggle-menu');
    if (menuToggleBtn) {
      menuToggleBtn.addEventListener('click', function () {
        var configSize = self.config.sidenav.size;
        var size = self.html.getAttribute('data-sidenav-size', configSize);

        if (size === 'full') {
          self.showBackdrop();
        } else {
          if (size === 'condensed') {
            self.changeLeftbarSize(
              configSize == 'condensed' ? 'default' : configSize,
              false
            );
          } else {
            self.changeLeftbarSize('condensed', false);
          }
        }

        // Todo: old implementation
        self.html.classList.toggle('sidebar-enable');
      });
    }
  }

  showBackdrop() {
    const backdrop = document.createElement('div');
    backdrop.id = 'custom-backdrop';
    backdrop.classList = 'offcanvas-backdrop fade show';
    document.body.appendChild(backdrop);
    document.body.style.overflow = 'hidden';
    if (window.innerWidth > 767) {
      document.body.style.paddingRight = '15px';
    }
    const self = this;
    backdrop.addEventListener('click', function (e) {
      self.html.classList.remove('sidebar-enable');
      self.hideBackdrop();
    });
  }

  hideBackdrop() {
    var backdrop = document.getElementById('custom-backdrop');
    if (backdrop) {
      document.body.removeChild(backdrop);
      document.body.style.overflow = null;
      document.body.style.paddingRight = null;
    }
  }

  initWindowSize() {
    var self = this;
    window.addEventListener('resize', function (e) {
      self._adjustLayout();
    });
  }

  _adjustLayout() {
    var self = this;

    if (window.innerWidth <= 767.98) {
      self.changeLeftbarSize('full', false);
    } else if (window.innerWidth >= 767 && window.innerWidth <= 1140) {
      if (self.config.sidenav.size !== 'full') {
        self.changeLeftbarSize('condensed', false);
      }
    } else {
      self.changeLeftbarSize(self.config.sidenav.size);
      self.changeLayoutMode(self.config.layout.mode);
    }
  }

  setSwitchFromConfig() {
    sessionStorage.setItem('__CONFIG__', JSON.stringify(this.config));
    // localStorage.setItem('__CONFIG__', JSON.stringify(this.config));

    document
      .querySelectorAll('#theme-settings-offcanvas input[type=checkbox]')
      .forEach(function (checkbox) {
        checkbox.checked = false;
      });

    var config = this.config;
    if (config) {
      var layoutColorSwitch = document.querySelector(
        'input[type=checkbox][name=data-bs-theme][value=' + config.theme + ']'
      );
      var layoutModeSwitch = document.querySelector(
        'input[type=checkbox][name=data-layout-mode][value=' +
          config.layout.mode +
          ']'
      );
      var topbarColorSwitch = document.querySelector(
        'input[type=checkbox][name=data-topbar-color][value=' +
          config.topbar.color +
          ']'
      );
      var menuColorSwitch = document.querySelector(
        'input[type=checkbox][name=data-menu-color][value=' +
          config.menu.color +
          ']'
      );
      var leftbarSizeSwitch = document.querySelector(
        'input[type=checkbox][name=data-sidenav-size][value=' +
          config.sidenav.size +
          ']'
      );
      var layoutPositionSwitch = document.querySelector(
        'input[type=radio][name=data-layout-position][value=' +
          config.layout.position +
          ']'
      );
      var sidebarUserSwitch = document.querySelector(
        'input[type=checkbox][name=sidebar-user]'
      );

      if (layoutColorSwitch) layoutColorSwitch.checked = true;
      if (layoutModeSwitch) layoutModeSwitch.checked = true;
      if (topbarColorSwitch) topbarColorSwitch.checked = true;
      if (menuColorSwitch) menuColorSwitch.checked = true;
      if (leftbarSizeSwitch) leftbarSizeSwitch.checked = true;
      if (layoutPositionSwitch) layoutPositionSwitch.checked = true;
      if (sidebarUserSwitch && config.sidenav.user.toString() === 'true')
        sidebarUserSwitch.checked = true;
    }
  }

  init() {
    this.initConfig();
    this.initSwitchListener();
    this.initWindowSize();
    this._adjustLayout();
    this.setSwitchFromConfig();
  }
}

new ThemeCustomizer().init();

document.addEventListener('DOMContentLoaded', function (event) {
  if ($('.test-slider').length) {
    const splide = new Splide('.test-slider', {
      type: 'loop',
      // perPage: 1.5,
      autoWidth: true,
      perMove: 1,
      // focus: 'center',
      drag: 'free',
      snap: true,
      autoplay: true,
      gap: '15px',
      pagination: false,
      breakpoints: {
        640: {
          perPage: 1,
          gap: '15px',
          autoWidth: false,
        },
      },
    });
    splide.mount();
  }

  if ($('.testimonials-slider').length) {
    const splide = new Splide('.testimonials-slider', {
      type: 'loop',
      // perPage: 3,
      autoWidth: true,
      perMove: 1,
      focus: 'center',
      drag: 'free',
      snap: true,
      // autoplay: true,
      gap: '60px',
      pagination: true,
      arrows: false,
      autoHeight: false,
      breakpoints: {
        1750: {
          gap: '40px',
        },
        640: {
          perPage: 1,
          gap: '15px',
          autoWidth: false,
          autoHeight: true,
        },
      },
    });
    splide.mount();
  }
  if ($('.video-slider').length) {
    const splide = new Splide('.video-slider', {
      type: 'loop',
      perPage: 3,
      autoWidth: false,
      perMove: 1,
      focus: 'center',
      drag: 'free',
      // snap: true,
      // autoplay: true,
      gap: '15px',
      pagination: true,
      arrows: true,
      autoHeight: true,
      breakpoints: {
        1750: {
          // perPage: 1,
          gap: '15px',
        },
        1200: {
          perPage: 2,
          gap: '15px',
        },
        640: {
          perPage: 1,
          gap: '15px',
          autoWidth: false,
          autoHeight: true,
        },
      },
    });
    splide.mount();
  }
});
